import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {Card} from "react-bootstrap"

const placeHolder =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=";

const Image = styled(Card.Img)`
transition: opacity 1s ease;
  // Add a smooth animation on loading
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  @keyframes loaded {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }
  // I use utilitary classes instead of props to avoid style regenerating
  &.loaded:not(.has-error) {
    animation: loaded 1500ms ease-in-out;
  }
  &.has-error {
    // fallback to placeholder image on error
    content: url(${placeHolder});
  }
`;

const LazyImage = ({ src, alt, imageLoadedCallback }) => {
  const [imageSrc, setImageSrc] = useState(placeHolder);
  const [imageRef, setImageRef] = useState();
  const [imgLoaded, setImgLoaded] = useState(false);

  const onLoad = (event) => {
    event.target.classList.add("loaded");
	imageLoadedCallback();
  };

  const onError = (event) => {
    event.target.classList.add("has-error");
  };

  useEffect(() => {
    let observer;
    let didCancel = false;

    if (imageRef && imageSrc !== src) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                setImageSrc(src);
                observer.unobserve(imageRef);
				setImgLoaded(true)
              }
            });
          },
          {
            threshold: 1,
            rootMargin: "75%",
          }
        );
        observer.observe(imageRef);
      } else {
        // Old browsers fallback
        setImageSrc(src);
      }
    }
    return () => {
      didCancel = true;
      // on component cleanup, we remove the listener
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef);
      }
    };
  }, [src, imageSrc, imageRef]);
  return (
    <Image
      ref={setImageRef}
      src={imageSrc}
      alt={alt}
      variant="top"
      onLoad={onLoad}
      onError={onError}
	  style={{opacity: imgLoaded ? 1 : 0}}
    />
  );
};

export default LazyImage;
