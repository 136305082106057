import { Link } from "gatsby";
import { DateTime } from "luxon";
import React, {useState} from "react";
import { Card } from 'react-bootstrap';
import DIMENSIONS from "../../helpers/DIMENSIONS";
import useWindowDimensions from "../../helpers/GetWindowDimensions";
import * as S from "../../styles/styles.css";
import LazyImage from "../LazyLoad/LazyLoad";

function PostListing({ postEdges, subMenuOpen }) {
  const postList = [];
  const { width } = useWindowDimensions();
  const [imageLoaded, setImageLoaded] = useState(false);

  postEdges.forEach((postEdge) => {
    postList.push({
      path: postEdge.node.fields.slug,
      tags: postEdge.node.frontmatter.tags,
      cover: postEdge.node.frontmatter.cover,
      title: postEdge.node.frontmatter.title,
      category: postEdge.node.frontmatter.category,
      date: DateTime.fromISO(postEdge.node.fields.date),
      excerpt: postEdge.node.excerpt,
      timeToRead: postEdge.node.timeToRead,
    });
  });

	const imageLoadedCallback = () => {
	setImageLoaded(true);
	};

  return (
    <S.GalleryWrapper
      className="d-flex flex-wrap"
      subMenuOpen={subMenuOpen}
    >
      {
        /* Your post list here. */
        postList.map((post) => (
          <Link to={post ? post.path : "#"} key={post.title}>
            <S.GalleryCard>
			<S.ImageWrapper>
			<LazyImage className="card-img-top w-100" src={post.cover} imageLoadedCallback={()=> imageLoadedCallback()} />
              </S.ImageWrapper>
              {width > DIMENSIONS.breakpoints.mobile && 
              <Card.Body>
                <Card.Title className="title">{post.title}</Card.Title>
                <Card.Text className="category">{post.category}</Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
                <Card.Footer>
                  <S.GalleryDate>
                    <h5 className="date">{post.date.c.year}</h5>
                  </S.GalleryDate>
                </Card.Footer>
              </Card.Body>}
            </S.GalleryCard>
          </Link>
        ))
      }
    </S.GalleryWrapper>
  );
}

export default PostListing;
