import { graphql } from "gatsby";
import React from "react";
import { Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import config from "../../data/SiteConfig";
import PostListing from "../components/PostListing/PostListing";
import Layout from "../layout";
import * as S from "../styles/styles.css";

export default function TagTemplate({ pageContext, data }) {
  const { tag } = pageContext;
  const postEdges = data.allMarkdownRemark.edges;
  return (
    <Layout title={`Tags: ${tag}`}>
      <S.GalleryRow>
        <Col>
          <S.GalleryRowInnerDiv className="listing-container">
            <div className="tag-container">
              <Helmet
                title={`Posts tagged as "${tag}" | ${config.siteTitle}`}
              />
              <PostListing postEdges={postEdges} />
            </div>
          </S.GalleryRowInnerDiv>
        </Col>
      </S.GalleryRow>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;
